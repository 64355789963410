import { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import pypLogoC from '../Imgs/newIcons/pypLogoC.png'

import { Collapse } from 'antd'

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false)

  const handleToggleMenu = () => {
    setToggleMenu((toggleMenu) => !toggleMenu)
  }

  const items = [
    {
      key: '1',
      label: (
        <div className="flex items-center justify-between mt-[-10px]">
          <motion.p
            whileTap={{ scale: 1.2 }}
            className="text-3xl  font-semibold"
          >
            Nosotros
          </motion.p>
          {/* <img src={triangularArrow} alt="triangularArrow" /> */}
        </div>
      ),
      children: (
        <motion.div
          initial={{ x: -400 }}
          transition={{ duration: 1.2 }}
          animate={{ x: 0 }}
        >
          <Link to={'/quienes-somos'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Quienes somos
            </motion.p>
          </Link>
          <Link to={'/lo-que-ofrecemos'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Lo que ofrecemos
            </motion.p>
          </Link>
          <Link to={'/como-funciona'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Como funciona
            </motion.p>
          </Link>
          <Link to={'/trabaja-con-nosotros'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Trabaja con nosotros
            </motion.p>
          </Link>
        </motion.div>
      ),
      showArrow: true
    },
    {
      key: '2',
      label: (
        <div className="flex items-center justify-between mt-[-10px]">
          <motion.p
            whileTap={{ scale: 1.2 }}
            className="text-3xl  font-semibold"
          >
            Productos
          </motion.p>
          {/* <img src={triangularArrow} alt="triangularArrow" /> */}
        </div>
      ),
      children: (
        <motion.div
          initial={{ x: -400 }}
          transition={{ duration: 1.2 }}
          animate={{ x: 0 }}
        >
          <Link to={'/planes'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Tarifas
            </motion.p>
          </Link>
          <Link to={'/registro-restaurante'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Registrar mi restaurante
            </motion.p>
          </Link>
          <Link to={'/descargas'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Descargar App
            </motion.p>
          </Link>
        </motion.div>
      ),
      showArrow: true
    },
    {
      key: '3',
      label: (
        <div className="flex items-center justify-between mt-[-10px]">
          <motion.p
            whileTap={{ scale: 1.2 }}
            className="text-3xl  font-semibold"
          >
            Legales
          </motion.p>
          {/* <img src={triangularArrow} alt="triangularArrow" /> */}
        </div>
      ),
      children: (
        <motion.div
          initial={{ x: -400 }}
          transition={{ duration: 1.2 }}
          animate={{ x: 0 }}
        >
          <Link to={'/terminos-condiciones'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Términos y condiciones
            </motion.p>
          </Link>
          <Link to={'/politica-privacidad'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Política de privacidad
            </motion.p>
          </Link>
          <Link to={'/condiciones-servicio'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Condiciones del servicio
            </motion.p>
          </Link>
        </motion.div>
      ),
      showArrow: true
    },
    {
      key: '4',
      label: (
        <div className="flex items-center justify-between mt-[-10px]">
          <motion.p
            whileTap={{ scale: 1.2 }}
            className="text-3xl  font-semibold"
          >
            Ayuda
          </motion.p>
          {/* <img src={triangularArrow} alt="triangularArrow" /> */}
        </div>
      ),
      children: (
        <motion.div
          initial={{ x: -400 }}
          transition={{ duration: 1.2 }}
          animate={{ x: 0 }}
        >
          <Link to={'/contactanos'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Soporte técnico
            </motion.p>
          </Link>
          <Link to={'/contactanos'} onClick={handleToggleMenu}>
            <motion.p whileTap={{ scale: 1.2 }} className=" mb-[10px]">
              Contacto
            </motion.p>
          </Link>
        </motion.div>
      ),
      showArrow: true
    }
  ]

  return (
    <div className="flex justify-center bg-Main">
      <div className="container">
        <div className=" flex justify-between items-center px-[20px] py-[20px]">
          <div className="flex">
            <Link to="/" className="flex items-center pr-[20px]  ">
              <figure className="flex">
                <img
                  src={pypLogoC}
                  className="mr-[10px] max-w-[210px] max-h-[40px]"
                />
              </figure>
            </Link>
            {/* <div className=" ml-[30px] hidden lg:block mt-[10px]">
              <div className="flex">
                <div className="flex items-center ml-[30px]">
                  <div className="mx-[15px]">
                    <ButtonSelect />
                  </div>
                  <div className="mx-[15px]">
                    <ButtonSelect2 />
                  </div>
                  <div className="mx-[15px] pt-[3px]">
                    <Link
                      to="/planes"
                      type="button"
                      className="mx-[20px]  pt-[4px] pb-[4px] px-[15px] hover:bg-[#bb3f16] rounded-3xl"
                    >
                      <button className="text-white itemNavBar">Tarifas</button>
                    </Link>
                  </div>
                  <div className="mx-[15px] pt-[3px]">
                    <Link
                      to="/contactanos"
                      type="button"
                      className="mx-[20px] pt-[4px] pb-[4px] px-[10px] hover:bg-[#bb3f16] rounded-3xl"
                    >
                      <button className="text-white itemNavBar">
                        Contacto
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
            <div className="text-center bg-white py-[10px] px-[15px] rounded-3xl text-xs md:text-lg">
              <a
                href="https://restaurantes.pideypasa.com/"
                className="font-bold text-Main"
                target="_blank"
                rel="noreferrer"
              >
                Iniciar sesión
              </a>
            </div>
          </motion.div>
        </div>

        <div
          className={
            toggleMenu
              ? 'navbar-menu relative z-50'
              : 'navbar-menu relative z-50 hidden'
          }
        >
          <div className="navbar-backdrop fixed inset-0 bg-black opacity-25"></div>

          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm bg-white border-r overflow-y-auto">
            <div className="flex items-center py-[23px] px-[23px] bg-Main">
              <Link
                className="mr-auto text-3xl font-bold leading-none"
                onClick={handleToggleMenu}
                to="/"
              >
                <img alt="" src={pypLogoC} width={230} className="h-full " />
              </Link>
              <button className="navbar-close" onClick={handleToggleMenu}>
                <svg
                  className="h-6 w-6 text-white cursor-pointer hover:text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>

            <Collapse
              size={'large'}
              accordion={true}
              ghost={true}
              items={items}
              expandIconPosition={'end'}
              className="p-[20px]"
            />

            <div className="mt-auto mb-[15px]">
              <p className="my-4 text-xs text-center">
                <span className="">Copyright © 2023</span>
              </p>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Navbar
